<template>
  <section id="contato">
    <div class="container">
      <div class="row justify-content-center mb-4">
        <div class="col-12 col-xl-10">
          <div class="d-flex justify-content-center">
            <div class="container-title d-flex align-items-center justify-content-center mb-4">
              <h2 class="text-center m-0">CONTATO</h2>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-10 text-center">
          <p style="font-family: 'Effra', sans-serif; font-size: 25px; color: #212B55;">Deixe sua mensagem que entraremos em contato.</p>
        </div>
      </div>
      <div id="modal" style="display: none;">
            <p id="p">Preencha todos os campos corretamente!</p>
            <div id="closebtn">
              <button id="close-modal">Fechar</button>
            </div>
          </div>
     
      <div class="row justify-content-center">
        <div class="col-12 col-xl-10">
          <form class="form-contact" @submit.prevent="sendEmail" action="#" value="Send">
            <div class="form-row">
              <div class="form-group col-md-6">
                <input v-model="user_name" name="user_name" type="text" class="form-control" id="nome" placeholder="Nome"/>
              </div>
              <div class="form-group col-md-6">
                <input v-model="tel" type="tel" name="tel" class="form-control" placeholder="Telefone" id="vlrtel"/>
              </div>
            </div>
            <div class="form-group">
              <input v-model="user_email" type="email" name="user_email" class="form-control" id="email" placeholder="Email"/>
            </div>
            <div class="form-group">
              <input v-model="assunto" name="assunto"  type="text"  class="form-control" id="assunto" placeholder="Assunto"/>
            </div>
            <textarea name="message" id="message" v-model="message" rows="10" placeholder="Mensagem" class="w-100 mb-3" style="resize: none"></textarea>
            <div class="d-flex justify-content-center mt-5">

                <button id="open-modal" @click="colorCampo"  type="submit"  class="btn btn-send">ENVIAR</button>
            
          </div>
          </form>
        </div>
      </div>
    </div>
    
  </section>

</template>


<script>
import emailjs from 'emailjs-com';
import IMask from 'imask';
// import axios from "axios";

export default {
  name: 'FormContact',
  data() {
    return {
      user_name: '',
      user_email: '',
      tel: '',
      assunto: '',
      message: ''
    }
  },
  mounted() {
    var tel  = document.getElementById('vlrtel');
        tel.addEventListener('keyup', function () {

            IMask(tel, {
                mask: [
                    { mask: '(00) 0000-0000', },
                    { mask: '(00) 00000-0000', },
                ],
                dispatch: function (appended, dynamicMasked) {
                    var value = (dynamicMasked.value + appended).replace(/\D/g, '');

                    if (value.length <= 10) {
                        return dynamicMasked.compiledMasks[0]
                    } else {
                        return dynamicMasked.compiledMasks[1]
                    }
                }
            })
        });

    function validaEmail(user_email) {
        var regex = /^([\w-]+(?:\.[\w-]+))@((?:[\w-]+\.)\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        return regex.test(user_email);
    }
    validaEmail(this.user_email) 
    var closeModalBtn = document.getElementById("close-modal");
    closeModalBtn.addEventListener("click", function() {
      document.getElementById('nome').style = "background-color:white";
      document.getElementById("modal").style.display = "none";
      document.querySelector("body").style.overflowY = "scroll";
      document.querySelector("textarea").disabled = false;
      document.getElementById("open-modal").disabled = false;
      const inputs = document.querySelectorAll('input');
          inputs.forEach(input => {
            input.disabled = false;
          });
    });
  },
  
  methods: {
    colorCampo(){
      document.getElementById('nome').style = "background-color:white";
      document.getElementById('vlrtel').style = "background-color:white";
      document.getElementById('email').style = "background-color:white";
          document.getElementById('assunto').style = "background-color:white";
          document.getElementById('message').style = "background-color:white";
          document.querySelector("textarea").disabled = true;
          const inputs = document.querySelectorAll('input');
          inputs.forEach(input => {
            input.disabled = true;
          });
        },
        sendEmail(e) {
          function openModal(){
            document.getElementById("open-modal");
            document.getElementById("modal").style.display = "block";
            document.getElementById("modal").style.backgroundColor = "#FFE3E3";
            document.getElementById("modal").style.border = "2px solid #b7182e";
            document.getElementById("p").innerHTML = "Preencha todos os campos corretamente!";
            document.getElementById("p").style.fontSize = "large";
            document.querySelector("body").style.overflowY = "hidden";
            document.getElementById("open-modal").disabled = true;
          }
          
          e.preventDefault(e);
          if(this.user_name == ''){ //FFACAC
            openModal()
            document.getElementById('nome').style = "background-color:#FFE3E3";
            // modal.style.display = "block";
          }
          else if(this.tel == ''){
            openModal()
            document.getElementById('vlrtel').style = "background-color:#FFE3E3";
          }
          else if(this.user_email == ''){
            openModal()
            document.getElementById('email').style = "background-color:#FFE3E3";
          }
          else if(this.assunto == ''){
            openModal()
            document.getElementById('assunto').style = "background-color:#FFE3E3";
          }
          else if(this.message == ''){
            openModal()
            document.getElementById('message').style = "background-color:#FFE3E3";
          }
          else{
          try {
            document.getElementById("open-modal");
            document.getElementById("modal").style.display = "block";
            document.getElementById("modal").style.backgroundColor = "#D8FFDD";
            document.getElementById("modal").style.border = "2px solid green";
            document.getElementById("p").innerHTML = "Mensagem enviada com sucesso!";
            document.getElementById("p").style.fontSize = "large";
            document.querySelector("body").style.overflowY = "hidden";
            document.querySelector("textarea").disabled = true;
            const inputs = document.querySelectorAll('input');
            inputs.forEach(input => {
            input.disabled = true;
          });
          document.getElementById("open-modal").disabled = true;

          emailjs.sendForm('service_3jqrp3l', 'template_24phllf',e.target,'P0PdeEyxZIedUYfLF',{
            
              user_name: this.user_name,
              user_email: this.user_email,
              tel: this.tel,
              assunto: this.assunto,
              message: this.message
          })
        } catch(error) {
          return error;
        }
        // Reset form field
        this.user_name = ''
        this.user_email = ''
        this.tel = ''
        this.assunto = ''
        this.message = ''
          }
        },
      }
    }
          

  
</script>

<style>
  section#contato {
    padding: 56px 0;
  }
  body{
    overflow-y: scroll;
    overflow-x: hidden;

  }
  input.form-control,
  textarea {
    border: 2px solid #B7182E !important;
    border-radius: 6px !important;
  }
  textarea {
    padding: 0.375rem 0.75rem;
  }
  .btn.btn-send {
    font-family: 'Effra Heavy', sans-serif;
    font-size: 25px;
    color: #FFFFFF;
    background-color: #212B55;
    width: 100%;
    max-width: 255.44px;
    height: 45px;
    border-radius: 20px;
    padding: unset;
  }
  .btn.btn-send:hover {
    color: #FFFFFF;
  }
  #modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #fff;
  /* border: 1px solid #000; */
  z-index: 99999;
  background-color: #FFE3E3;
    border-radius: 10px;
    border: 2px solid #b7182e;
}

#modal p {
  margin-bottom: 10px;
  font-size: large;
  /* font-family: 'Effra Heavy', sans-serif; */

}

#close-modal {
  color: #FFFFFF;
  background-color: #212B55;
  border: 1px solid #D8FFDD;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
}
#closebtn{
  display: flex;
  justify-content: center;
}


</style>