<template>
  <footer>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-xl-4 d-flex flex-column align-items-center justify-content-center">
                <h2 class="mb-4">FALE CONOSCO</h2>
                <div class="informations d-flex flex-column">
                    <div class="informations-phone">
                        <img src="../assets/img/icon-telefone.svg" alt="ícone branco telefone">
                        <span class="text-light ml-3">(13) 2191-2018</span>
                    </div>
                    <div class="informations-adress d-flex align-items-center">
                        <img src="../assets/img/icon-local.svg" alt="ícone branco pin localização">
                        <span class="text-light ml-3">Av. Presidente Kennedy - 7954 <br> Anexo Escola - Ocian, 11705-000</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-6 d-flex justify-content-center mt-5 mt-xl-0">
                <a href="https://emec.mec.gov.br/emec/consulta-cadastro/detalhamento/d96957f455f6405d14c6542552b0f6eb/MzQyOA==">
                    <div class="container-emec d-flex align-items-center justify-content-center">
                        <img src="../assets/img/Logo_Emec.svg" class="logo-emec" alt="Logo do Emec">
                        <img src="../assets/img/Qrcode_Emec.svg" class="qrcode" alt="Qrcode redirecionando para o site do Emec" width="96" height="96">
                        <span>Consulte aqui o cadastro da instituição</span>
                    </div>
                </a>
            </div>
        </div>
    </div>
  </footer>
</template>

<script>
export default {
    name: 'FooterBar',

}
</script>

<style>
footer {
    background-color: #B7182E;
    padding: 77px 0;
}
footer h2 {
    font-family: 'Effra Bold', sans-serif;
    color: #FFFFFF;
    text-decoration: underline;
}
.container-emec {
    background-color: #FFFFFF;
    border-radius: 18px;
    width: 100%;
    max-width: 520px;
    padding: 15px 30px;
}
.container-emec span {
    font-family: 'Effra Bold', sans-serif;
    font-size: 22px;
    color: #212B55;
}
.qrcode {
    margin: 0 38px;
}

@media (max-width: 1199px) {
    .informations-phone {
        margin-right: 20px;
    }
}

@media (max-width: 767px)  {
    .logo-emec {
        max-width: 72px;
    }
    .qrcode {
        max-width: 51px;
        margin: 0 18px;
    }
    .container-emec span {
        font-size: 16px;
    }
    .informations-adress img {
        height: 27px
    }
}

</style>
