<template>
    <header :class="{ 'fixed-menu': isFixed }">
      <div style="width: 100%; max-width: 1920px; display: flex; align-items: center; justify-content: center;">
        <img class="site-logo" alt="Logo Faculdade Alfamérica" src="../assets/img/logo.svg">
        <nav class="w-100">
          <ul class="container-menu d-flex flex-wrap align-items-center justify-content-center w-100 h-100">
            <li>
              <a @click.prevent="scrollToSection('#quemsomos')">QUEM SOMOS</a>
            </li>
            <li>
              <a @click.prevent="scrollToSection('#graduacao')">GRADUAÇÃO</a>
            </li>
            <li>
              <a @click.prevent="scrollToSection('#contato')">CONTATO</a>
            </li>
          </ul>

        </nav>
      
      </div>
    </header>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      isFixed: false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const menuTop = this.$el.offsetTop;
      const scrollTop = window.pageYOffset;
      if (scrollTop > menuTop) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
    scrollToSection(sectionId) {
      const section = document.querySelector(sectionId);
      const navHeight = document.querySelector('nav').offsetHeight;
      const sectionTop = section.offsetTop - navHeight;
      window.scrollTo({
        top: sectionTop,
        behavior: 'smooth'
      });
    },

  }
}
</script>

<style>
  header.fixed-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }

  header {
    background-color: #212B55;
    display: flex !important;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 7px 5px rgba(0, 0, 0, 0.3);
  }

  .container-menu li a {
    font-family: 'Effra Bold', sans-serif;
    color: #FFFFFF !important;
    cursor: pointer;
  }
  li:not(:nth-last-child(1)) {
    margin-right: 112px;
  }

  @media (min-width: 577px) {
    header {
      height: 81px;
    }
  }

  @media (max-width: 576px) {
    .site-logo {
      max-width: 48px; 
    }
    .container-menu {
      font-size: 14px;
    }
    .container-menu li:not(:nth-last-child(1)) {
      margin-right: 16px;
    }
  }

</style>